import { ReactFC } from '@milo/react';
import { activateLocale, i18n, I18nProvider as LinguiI18nProvider } from '@milo/i18n';
import { Lang } from '@milo/types';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const I18nProvider: ReactFC = ({ children }) => {
  const { locale } = useRouter();

  useEffect(() => {
    activateLocale((locale || 'fr') as Lang);
  }, [locale]);

  return <LinguiI18nProvider i18n={i18n}>{children}</LinguiI18nProvider>;
};
