import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { Lang } from '@milo/types';
import { messages as en } from '../locale/en';
import { messages as fr } from '../locale/fr';
import { enPlurals, frPlurals } from './plurals';

const dictionnary = {
  en,
  fr,
};

i18n.loadLocaleData({ en: { plurals: enPlurals }, fr: { plurals: frPlurals } });

const activateLocale = (locale: Lang) => {
  i18n.load(locale, dictionnary[locale]);
  i18n.activate(locale);
};

export { I18nProvider, i18n, activateLocale };
